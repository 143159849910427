import React from 'react';
import { GlobalHOC, NullCheckHoc } from '@yas/platform';
import { Cookie } from '@yas/platform/fwad-index';
import './cookie.scss';
import './lang/cookie-ar.scss';
import './announcements.scss';
import './lang/announcements-ar.scss';

const FWADCookie = ({ fields }) => <Cookie {...fields} />;

export default NullCheckHoc(GlobalHOC(FWADCookie));
